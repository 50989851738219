import React from "react";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// images
import call from "../../images/how_it_works_icon1.png";
import office from "../../images/how_it_works_icon2.png";
import form from "../../images/how_it_works_icon3.png";
import magnifyingGlass from "../../images/how_it_works_icon4.png";
import watches from "../../images/how_it_works_icon5.png";

const steps = [
  {
    src: call,
    description: "Call or email us to set up an appointment.",
    alt: "call icon",
  },
  {
    src: office,
    description: "Drop off your watch(es) at our office for an estimate.",
    alt: "office icon",
  },
  {
    src: form,
    description:
      "We'll contact you with an estimate and start the job with your approval.",
    alt: "form icon",
  },
  // {
  //   src: magnifyingGlass,
  //   description: "Track your job online for your watch status and any updates.",
  //   alt: "magnifying glass icon",
  // },
  {
    src: watches,
    description:
      "We'll repair your watch(es) and notify you when the job is finished!",
    alt: "watches icon",
  },
];

const HowItWorksSection = (props) => {
  return (
    <Grid container item xs={12} justifyContent="center">
      <Typography variant="h1" marginBottom={6}>
        How it Works
      </Typography>
      <Grid container item xs={12} justifyContent="center">
        <Stepper
          activeStep={-1}
          alternativeLabel
          orientation={props.stepperOrientation}
          sx={{
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              sx={{
                "& .Mui-disabled .MuiStepIcon-root": {
                  color: "primary.stepperBlue",
                  fontSize: { md: "1.8rem" },
                },
                "& .Mui-disabled .MuiStepLabel-label": {
                  color: "text.black",
                },
                ".MuiStepIcon-text": {
                  fontFamily: "Source Sans Pro, sans-serif",
                },
              }}
            >
              <StepLabel>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  marginBottom={10}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    maxWidth={{ xs: "50%", lg: "70%" }}
                  >
                    <Grid item container xs={12} justifyContent="center">
                      <Box
                        component="img"
                        src={step.src}
                        alt={step.alt}
                        width="100px"
                      />
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                      <Typography variant="body2" marginTop={3}>
                        {step.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default HowItWorksSection;
