import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const initialWatchValues = {
  completeMovementService: "",
  batteryService: "",
  maintenanceService: "",
  movementParts: "",
  crystal: "",
  crown: "",
  pushers: "",
  caseTube: "",
  bezel: "",
  hands: "",
  calendar: "",
  dial: "",
  bracelet: "",
  clasp: "",
  buckle: "",
  refinish: "",
  adjustTiming: "",
  gaskets: "",
  reseal: "",
  waterTest: "",
  sizing: "",
  authenticityCheck: "",
  estimateFee: "",
};

// props from jobForm = watch={null}, jobData, setJobData, addWatch, handleCloseWatchDialog
// props from watchCard = watch, index, updateWatch, handleCloseWatchDialog
function WatchForm(props) {
  const [watchFormName, setWatchFormName] = useState("");
  const [watchFormDescr, setWatchFormDescr] = useState(initialWatchValues);
  const [watchFormTotal, setWatchFormTotal] = useState(0);

  useEffect(() => {
    console.log("useEffect on mount of watchForm.js");
    if (props.watch) {
      setWatchFormName(props.watch.name);
      setWatchFormDescr({
        ...watchFormDescr,
        ...props.watch.description,
      });
      setWatchFormTotal(props.watch.cost);
    }
  }, []);

  useEffect(() => {
    console.log("useEffect of watchForm.js on jobData.currentWatchDescr");
    setWatchFormTotal(calculateWatchTotal);
  }, [watchFormDescr]);

  const calculateWatchTotal = () => {
    console.log("calculateWatchTotal of watchForm.js");
    let total = 0;
    for (const service in watchFormDescr) {
      if (watchFormDescr[service] !== "") {
        total += parseInt(watchFormDescr[service]);
      }
    }
    return total;
  };

  const handleAddService = (e) => {
    const { name, value } = e.target;
    setWatchFormDescr({
      ...watchFormDescr,
      [name]: value,
    });
  };

  const handleCloseDialog = () => {
    props.handleCloseWatchDialog();
  };

  const addWatch = () => {
    console.log("addWatch activated in watchForm.js");
    for (const key in watchFormDescr) {
      if (watchFormDescr[key] === null || watchFormDescr[key] === "") {
        delete watchFormDescr[key];
      }
    }
    const newWatch = {
      name: watchFormName,
      cost: watchFormTotal,
      description: watchFormDescr,
    };
    props.addWatch(newWatch);
    setWatchFormName("");
    setWatchFormDescr(initialWatchValues);
    setWatchFormTotal(0);
  };

  const editWatch = () => {
    console.log("editWatch in watchForm.js");
    for (const key in watchFormDescr) {
      if (watchFormDescr[key] === null || watchFormDescr[key] === "") {
        delete watchFormDescr[key];
      }
    }
    const updatedWatch = {
      name: watchFormName,
      cost: watchFormTotal,
      description: watchFormDescr,
    };
    props.updateWatch(props.index, updatedWatch);
    setWatchFormName("");
    setWatchFormDescr(initialWatchValues);
    setWatchFormTotal(0);
    handleCloseDialog();
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {props.watch ? "Update Watch" : "Add New Watch"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Watch Information</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus
          variant="outlined"
          label="Watch Name"
          fullWidth
          required
          value={watchFormName}
          onChange={(e) => setWatchFormName(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="completeMovementService"
          label="Complete Movement Service"
          placeholder="Complete Movement Service"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.completeMovementService}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="maintenanceService"
          label="Maintenance Service"
          placeholder="Maintenance Service"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.maintenanceService}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="batteryService"
          label="Battery Service"
          placeholder="Battery Service"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.batteryService}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="movementParts"
          label="Movement Parts"
          placeholder="Movement Parts"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.movementParts}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="crystal"
          label="Crystal"
          placeholder="Crystal"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.crystal}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="crown"
          label="Crown"
          placeholder="Crown"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.crown}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="pushers"
          label="Pushers"
          placeholder="Pushers"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.pushers}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="caseTube"
          label="Case Tube"
          placeholder="Case Tube"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.caseTube}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="bezel"
          label="Bezel"
          placeholder="Bezel"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.bezel}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="hands"
          label="Hands"
          placeholder="Hands"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.hands}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="calendar"
          label="Calendar"
          placeholder="Calendar"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.calendar}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="dial"
          label="Dial"
          placeholder="Dial"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.dial}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="bracelet"
          label="Bracelet"
          placeholder="Bracelet"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.bracelet}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="clasp"
          label="Clasp"
          placeholder="Clasp"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.clasp}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="buckle"
          label="Buckle"
          placeholder="Buckle"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.buckle}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="refinish"
          label="Refinish"
          placeholder="Refinish"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.refinish}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="adjustTiming"
          label="Adjust Timing"
          placeholder="Adjust Timing"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.adjustTiming}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="gaskets"
          label="Gaskets"
          placeholder="Gaskets"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.gaskets}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="reseal"
          label="Reseal"
          placeholder="Reseal"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.reseal}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="waterTest"
          label="Water Test"
          placeholder="Water Test"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.waterTest}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="sizing"
          label="Sizing"
          placeholder="Sizing"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.sizing}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="authenticityCheck"
          label="Authenticity Check"
          placeholder="Authenticity Check"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.authenticityCheck}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="estimateFee"
          label="Estimate Fee"
          placeholder="Estimate Fee"
          variant="outlined"
          type="number"
          fullWidth
          value={watchFormDescr.estimateFee}
          onChange={(e) => handleAddService(e)}
        />
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6">Total: ${watchFormTotal}</Typography>
      </Grid>
      <Grid item xs={2} justifyItems="end">
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={2} justifyItems="end">
        {props.watch && (
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={editWatch}
            disabled={!watchFormName || watchFormTotal === 0}
          >
            Update
          </Button>
        )}
        {!props.watch && (
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={addWatch}
            disabled={!watchFormName || watchFormTotal === 0}
          >
            Add
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
export default WatchForm;
