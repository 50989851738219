import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import dennis_working from "../../images/dennis_working.jpg";

const OurStorySection = () => {
  return (
    <>
      <Grid item container xs={12} lg={6}>
        <Box component="img" src={dennis_working} alt="guy" width="100%" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        lg={6}
        backgroundColor="background.blue"
        alignItems="flex-start"
        alignContent="center"
        padding={{ xs: 5, lg: 15, xl: 20 }}
      >
        <Grid item container xs={12}>
          <Typography variant="h1" marginBottom={3}>
            Over 25 Years of Quality&nbsp;Work
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography variant="body2" marginBottom={6}>
            With a keen eye for detail, Dennis has been making people satisfied
            with professional&nbsp;watch&nbsp;care.
          </Typography>
        </Grid>
        <Grid item container xs={12} lg={6} xl={4}>
          <Button
            color="secondary"
            variant="contained"
            href="/about"
            size="large"
            fullWidth
          >
            Our Story
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default OurStorySection;
