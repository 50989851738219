import React, { useEffect, useState } from "react";
import WatchForm from "./watchForm";
import WatchCard from "./watchCard";
import { API } from "../config";
import axios from "axios";
import Moment from "moment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Button, CardContent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Container from "@mui/material/Container";
import LoopIcon from "@mui/icons-material/Loop";

const statuses = [
  {
    value: "Estimate",
    label: "Estimate Completed",
  },
  {
    value: "Approval",
    label: "Waiting for approval",
  },
  {
    value: "Parts",
    label: "Waiting for parts",
  },
  {
    value: "Started",
    label: "Started",
  },
  {
    value: "QualityCheck",
    label: "Quality Check",
  },
  {
    value: "Completed",
    label: "Completed",
  },
  {
    value: "Delivered",
    label: "Delivered",
  },
];

const initialJobValues = {
  number: "",
  date: "",
  name: "",
  email: "",
  phone: "",
  watches: [],
  estimate: 0,
  cost: 0,
  comments: "",
  status: "",
};

const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

// props from jobList = createJob, editJob, handleCloseCreate
// props from jobCard = job, updated, handleCloseEdit
function JobForm(props) {
  const [jobData, setJobData] = useState({
    number: "",
    date: "",
    name: "",
    email: "",
    phone: "",
    watches: [],
    estimate: "",
    cost: "",
    comments: "",
    status: "",
  });

  const [openWatchDialog, setOpenWatchDialog] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSwitch = () => {
    setChecked((prev) => !prev);
  };

  const handleOpenWatchDialog = () => {
    setOpenWatchDialog(true);
  };

  const handleCloseWatchDialog = () => {
    setOpenWatchDialog(false);
  };

  useEffect(() => {
    if (props.job) {
      props.job.date = Moment(props.job.date).format("YYYY-MM-DD");
      setJobData(props.job);
    }
  }, []);

  const generateJobNumber = () => {
    let result = "";
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setJobData({ ...jobData, number: result });
  };

  const addWatch = (newWatch) => {
    const newWatches = jobData.watches;
    newWatches.push(newWatch);
    const updatedEstimate = calculateEstimate(jobData.watches);
    setJobData({
      ...jobData,
      watches: newWatches,
      estimate: updatedEstimate,
      cost: updatedEstimate,
    });
    handleCloseWatchDialog();
  };

  function updateWatch(index, updatedWatch) {
    console.log("updateWatch in jobForm.js");
    const updatedWatches = [...jobData.watches];
    updatedWatches[index] = updatedWatch;
    const updatedEstimate = calculateEstimate(updatedWatches);
    setJobData({
      ...jobData,
      watches: updatedWatches,
      estimate: updatedEstimate,
      cost: updatedEstimate,
    });
    handleCloseWatchDialog();
  }

  const deleteWatch = (index) => {
    console.log("deleteWatch in jobForm.js");
    const updatedWatches = [...jobData.watches];
    updatedWatches.splice(index, 1);
    const updatedEstimate = calculateEstimate(updatedWatches);
    setJobData({
      ...jobData,
      watches: updatedWatches,
      estimate: updatedEstimate,
      cost: updatedEstimate,
    });
  };

  const calculateEstimate = (watches) => {
    console.log("inside calculateEstimate");
    let total = 0;
    watches.forEach((watch) => {
      total += parseInt(watch.cost);
    });
    console.log(total);
    //setJobData({ ...jobData, estimate: total, cost: total });
    console.log("end of calculateEstimate");
    return total;
  };

  function handleSubmit(e) {
    e.preventDefault();

    // if (props.job) {
    //   axios
    //     .put("http://localhost:5000/jobs/" + jobData.number, jobData)
    //     .then((res) => {
    //       handleCloseWatchDialog();
    //       props.handleCloseEdit();
    //       props.editJob();
    //       alert("Job successfully updated!");
    //     })
    //     .catch((err) => {
    //       alert("ERROR: Job could not be updated");
    //     });
    // } else {
    //   try {
    //     axios
    //       .get("http://localhost:5000/trackJob/" + jobData.number, {
    //         params: { number: jobData.number },
    //       })
    //       .then((response) => {
    //         if (response.data.length !== 0) {
    //           alert(
    //             'Error: Job Number "' + jobData.number + '" already exists'
    //           );
    //         } else {
    //           axios
    //             .post("http://localhost:5000/jobs", jobData)
    //             .then((res) => {
    //               alert("Job successfully added!");
    //               //props.handleCloseEdit();
    //               props.handleCloseCreate();
    //               setJobData(initialJobValues);
    //             })
    //             .catch((err) => {
    //               alert("ERROR: Job could not be added");
    //             });
    //           props.createJob();
    //         }
    //       });
    //   } catch (err) {
    //     alert("POOPY PANTS IN jobForm.js!!!");
    //   }
    // }
    if (props.job) {
      axios
        .put(`${API}/jobs/${jobData.number}`, jobData)
        .then((res) => {
          handleCloseWatchDialog();
          props.handleCloseEdit();
          props.editJob();
          alert("Job successfully updated!");
        })
        .catch((err) => {
          alert("ERROR: Job could not be updated");
        });
    } else {
      try {
        axios
          .get(`${API}/trackJob/${jobData.number}`, {
            params: { number: jobData.number },
          })
          .then((response) => {
            if (response.data.length !== 0) {
              alert(
                'Error: Job Number "' + jobData.number + '" already exists'
              );
            } else {
              axios
                .post(`${API}/jobs`, jobData)
                .then((res) => {
                  alert("Job successfully added!");
                  props.handleCloseCreate();
                  setJobData(initialJobValues);
                })
                .catch((err) => {
                  alert("ERROR: Job could not be added");
                });
              props.createJob();
            }
          });
      } catch (err) {
        alert("POOPY PANTS IN jobForm.js!!!");
      }
    }
  }

  return (
    <Container maxWidth="lg" sx={{ padding: 5, margin: "auto" }}>
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardContent>
          <Typography gutterBottom variant="h2">
            {!props.job && "Create a New Job"}
            {props.job && "Update Job"}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {!props.job && (
                <>
                  <Grid item xs={10}>
                    <TextField
                      label="Job Number"
                      placeholder="Job Number"
                      variant="outlined"
                      required
                      fullWidth
                      value={jobData.number}
                      onChange={(e) =>
                        setJobData({ ...jobData, number: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      startIcon={<LoopIcon />}
                      onClick={generateJobNumber}
                      sx={{ paddingBottom: 0 }}
                    >
                      Generate Job Number
                    </Button>
                  </Grid>
                </>
              )}

              {props.job && (
                <Grid item xs={12}>
                  <TextField
                    label="Can not change Job Number"
                    placeholder="Job Number"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={jobData.number}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  label="Customer Name"
                  placeholder="Customer Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={jobData.name}
                  onChange={(e) =>
                    setJobData({ ...jobData, name: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="email"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  required
                  value={jobData.email}
                  onChange={(e) =>
                    setJobData({ ...jobData, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  variant="outlined"
                  fullWidth
                  required
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  value={jobData.phone}
                  onChange={(e) =>
                    setJobData({ ...jobData, phone: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Estimate"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={jobData.estimate}
                  onChange={(e) =>
                    setJobData({ ...jobData, estimate: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Cost"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={jobData.cost}
                  onChange={(e) =>
                    setJobData({ ...jobData, cost: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Status"
                  fullWidth
                  required
                  value={jobData.status}
                  onChange={(e) =>
                    setJobData({ ...jobData, status: e.target.value })
                  }
                >
                  {statuses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date"
                  type="date"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  value={jobData.date}
                  onChange={(e) =>
                    setJobData({ ...jobData, date: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comments"
                  placeholder="Comments"
                  variant="outlined"
                  fullWidth
                  value={jobData.comments}
                  onChange={(e) =>
                    setJobData({ ...jobData, comments: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleOpenWatchDialog}
                >
                  Add Watch
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  endIcon={<SendIcon />}
                  color="success"
                  disabled={
                    !jobData.number ||
                    !jobData.name ||
                    !jobData.email ||
                    !jobData.phone ||
                    !jobData.estimate ||
                    !jobData.cost ||
                    !jobData.status ||
                    !jobData.date ||
                    jobData.watches.length === 0
                  }
                >
                  {props.job ? "Update" : "Submit"}
                </Button>
              </Grid>
            </Grid>

            <Dialog
              open={openWatchDialog}
              onClose={handleCloseWatchDialog}
              fullWidth
              maxWidth="md"
            >
              <WatchForm
                watch={null}
                jobData={jobData}
                setJobData={setJobData}
                addWatch={addWatch}
                handleCloseWatchDialog={handleCloseWatchDialog}
              />
            </Dialog>
          </form>
        </CardContent>
      </Card>

      <FormGroup sx={{ p: 0, marginTop: 1 }}>
        <FormControlLabel
          sx={{ p: 0, marginY: 0 }}
          control={
            <Switch
              checked={checked}
              onChange={handleSwitch}
              inputProps={{ "aria-label": "controlled" }}
              disabled={jobData.watches.length === 0}
            />
          }
          label="Expand all"
        />
      </FormGroup>
      <Grid container spacing={4} sx={{ marginY: 0 }}>
        {React.Children.toArray(
          jobData.watches.map((watch, index) => (
            <Grid item xs={4}>
              <WatchCard
                watch={watch}
                index={index}
                expandAll={checked}
                editable={true}
                deleteWatch={deleteWatch}
                updateWatch={updateWatch}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}

export default JobForm;
