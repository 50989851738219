import React, { useState } from "react";
import { API } from "../config";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import axios from "axios";

const TrackJob = (props) => {
  const [jobNum, setJobNum] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API}/trackJob/${jobNum}`, {
        params: { number: jobNum },
      });
      if (response.data.length === 0) {
        alert('Error: Job Number "' + jobNum + '" could not be found');
        setJobNum("");
      } else {
        navigate("/trackJob/" + jobNum);
      }
    } catch (err) {
      alert(
        "Error retrieving your information. If this happens again, please contact us."
      );
    }
  };

  return (
    <Grid container xs={12} textAlign="center" padding={{ xs: 2, md: 5 }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ height: { xs: "70vh", lg: "70vh", width: "100vw" } }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="title">Track your Job</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Please enter the Job Number you received in our email.
              </Typography>
            </Grid>
            <Grid item xs={12} marginBottom={3}>
              <Typography variant="body1">
                Didn't receive a Job Number?{" "}
                <Typography
                  component="a"
                  href="tel:+19544386368"
                  variant="body1"
                  fontWeight="600"
                  color="black"
                  sx={{ textDecorationLine: "none" }}
                >
                  Call{" "}
                </Typography>
                or{" "}
                <Typography
                  component="a"
                  href="mailto:dwwatchservice@gmail.com"
                  variant="body1"
                  fontWeight="600"
                  color="black"
                  sx={{ textDecorationLine: "none" }}
                >
                  Email{" "}
                </Typography>
                us for assistance.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              marginBottom={1}
              justifyContent="center"
            >
              <TextField
                variant="outlined"
                size="medium"
                autoComplete="off"
                required
                label="Job Number"
                value={jobNum}
                onChange={(e) => setJobNum(e.target.value)}
                sx={{ width: { xs: "100%", sm: "36rem" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                endIcon={<SendIcon />}
                disabled={!jobNum}
                type="submit"
                sx={{
                  width: { xs: "100%", sm: "16rem" },
                  ":hover": {
                    bgcolor: "secondary.dark",
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
export default TrackJob;
