import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Paper from "@mui/material/Paper";

const reviews = [
  {
    score: 5,
    body:
      '"We found Dennis via Google maps because he was close and I\'m glad he was! Very knowledgeable and nice. I trust him much more than a random jeweler for my higher end watches. :) Thanks Dennis!"',
    author: "Victoria L.",
    source: "Google",
  },
  {
    score: 5,
    body:
      '"He took great care repairing my watch. He explained in detail what needed to be done to bring it to like new condition. Knowledgable, very skilled work at a fair price."',
    author: "Christian A.",
    source: "Google",
  },
  {
    score: 5,
    body:
      '"I have been using Mr Wong for our watch repair for years. He is professional, trustworthy, courteous, and completes the work very quickly when possible. I wouldn\'t take my watches to anyone else."',
    author: "Stephanie C.",
    source: "Yelp",
  },
  {
    score: 5,
    body:
      '"Fixed a Victorinox chronograph that I dropped and damaged. Also checked the water resistance. Price was reasonable. Will use for service on my more expensive pieces in the future."',
    author: "Cory T.",
    source: "Google",
  },
];

const ReviewSection = (props) => {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        textAlign="center"
        marginBottom={2}
      >
        <Typography variant="h1" marginBottom={3}>
          Reviews From Satisfied&nbsp;Customers
        </Typography>
      </Grid>
      <Grid container item spacing={{ xs: 6, md: 8 }}>
        {reviews.map((review) => (
          <Grid
            container
            item
            xs={12}
            sm={6}
            xl={3}
            sx={{ position: "relative" }}
          >
            <Paper
              sx={{
                position: "relative",
                borderRadius: 0,
                backgroundColor: "#F9FCFF",
                boxShadow: "3px 5px 12px 3px rgba(74, 82, 87, 0.1)",
                height: {
                  sm: "31rem",
                  md: "22rem",
                  lg: "24rem",
                  xl: "31rem",
                },
                paddingX: 5,
              }}
            >
              <Grid container marginBottom={2}>
                <Grid item container xs={12} marginTop={4} marginBottom={2}>
                  <Rating
                    size="large"
                    defaultValue={review.score}
                    readOnly
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "secondary.main",
                      },
                    }}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Typography variant="body1" color="text.black">
                    {review.body}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                textAlign="right"
                marginBottom={{ xs: 3, xl: 0 }}
                sx={{
                  position: { sm: "absolute" },
                  bottom: { sm: "0px", lg: "10px", xl: "24px" },
                  right: { sm: "40px", lg: "40px" },
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.dark">
                    - {review.author}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body4" color="text.dark">
                    From {review.source}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ReviewSection;
