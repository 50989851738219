import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const PrivacyPolicyPage = () => {
  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ paddingTop: { xs: 6, md: 10 }, paddingX: { xs: 3, md: 20 } }}
    >
      <Grid container>
        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="title">
            Privacy Policy of Swiss Watch Repair
          </Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="body2">
            Swiss Watch Repair operates the{" "}
            <Typography
              component="a"
              href="https://swisswatchrepair.net"
              variant="body2"
              fontWeight="600"
              color="black"
              sx={{ textDecorationLine: "none" }}
            >
              https://swisswatchrepair.net
            </Typography>{" "}
            website.
          </Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="body2">
            This page is used to inform website visitors regarding our policies
            with the collection, use, and disclosure of Personal Information if
            anyone decides to use the swisswatchrepair.net website.
          </Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            If you choose to use our Service, then you agree to the collection
            and use of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">
            Information Collection and&nbsp;Use
          </Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and email
            address. The information that we collect will only be used to
            contact or identify you.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">Log Data</Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us that is called Log
            Data. This Log Data may include information such as your computer’s
            Internet Protocol ("IP") address, browser version, pages of our
            Service that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">Cookies</Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="body2">
            Cookies are files with small amount of data that is commonly used an
            anonymous unique identifier. These are sent to your browser from the
            website that you visit and are stored on your computer’s hard drive.
          </Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            Our website uses these "cookies" to collection information and to
            improve our Service. You have the option to either accept or refuse
            these cookies, and know when a cookie is being sent to your
            computer. If you choose to refuse our cookies, you may not be able
            to use some portions of our Service.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">Security</Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">Links to Other Sites</Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">Changes to This Privacy Policy</Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </Typography>
        </Grid>

        <Grid container item xs={12} marginBottom={2}>
          <Typography variant="h1">Contact Us</Typography>
        </Grid>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="body2">
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to{" "}
            <Typography
              component="a"
              href="/contact"
              variant="body2"
              fontWeight="600"
              color="black"
              sx={{ textDecorationLine: "none" }}
            >
              contact us.
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
export default PrivacyPolicyPage;
