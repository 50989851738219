import React, { useState } from "react";
import Typography from "@mui/material/Typography";

const ContactPage = () => {
  return (
    <div align="center">
      <Typography variant="h1" color="primary">
        ERROR: Page not found
      </Typography>
    </div>
  );
};

export default ContactPage;
