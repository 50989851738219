import React from "react";
import { useEffect, useState } from "react";
import JobForm from "./jobForm";
import { API } from "../config";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Moment from "moment";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function createData(watchName, watchCost, watchDescription) {
  return { watchName, watchCost, watchDescription };
}

function clarifyDescription(desc) {
  return (
    desc.charAt(0).toUpperCase() +
    desc.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2")
  );
}

// each row is a watch for a job
function Row({ watch }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ p: 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {watch.watchName}
        </TableCell>
        <TableCell>{watch.watchCost}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="watches">
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell>Cost ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(watch.watchDescription).map((key) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {clarifyDescription(key)}
                      </TableCell>
                      <TableCell>{watch.watchDescription[key]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// props = job, deleteJob, editJob
function JobCard(props) {
  const [expanded, setExpanded] = useState(false);
  const [watches, setWatches] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const handleOpenDelete = () => {
    setDeleteOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleOpenEdit = () => {
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const formatDate = (date) => {
    return Moment(date).format("l");
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    console.log("USEEFFECT of JOBCARD");
    calculateWatches();
  }, [props.job]);

  const calculateWatches = () => {
    let tempWatches = [];
    for (var watch in props.job.watches) {
      tempWatches.push(
        createData(
          props.job.watches[watch].name,
          props.job.watches[watch].cost,
          props.job.watches[watch].description
        )
      );
    }
    setWatches(tempWatches);
  };

  const handleDelete = async () => {
    setDeleteOpen(false);
    // try {
    //   await axios.delete("http://localhost:5000/jobs/" + props.job.number, {
    //     params: { number: props.job.number },
    //   });
    //   props.deleteJob(props.job.number);
    // } catch (err) {
    //   alert("Job could not be deleted");
    // }
    try {
      await axios.delete(`${API}/jobs/${props.job.number}`, {
        params: { number: props.job.number },
      });
      props.deleteJob(props.job.number);
    } catch (err) {
      alert("Job could not be deleted");
    }
  };

  const updated = () => {
    setEditOpen(false);
  };

  return (
    <Card variant="outlined" sx={{ boxShadow: 2, borderRadius: 3 }}>
      <CardHeader
        title={
          <Typography variant="h4" color={"white"}>
            {props.job.number}
          </Typography>
        }
        subheader={
          <>
            <Typography variant="h6" color="info.main">
              {props.job.name}
            </Typography>
            <Typography variant="h6" color="info.main">
              {formatDate(props.job.date)}
            </Typography>
            <Typography variant="h6" color="info.main">
              Status: {props.job.status}
            </Typography>
            <Typography variant="h6" color="info.main">
              Total: ${props.job.estimate}
            </Typography>
          </>
        }
        action={
          <>
            <Tooltip title="Delete" arrow>
              <IconButton onClick={handleOpenDelete}>
                <DeleteTwoToneIcon sx={{ color: "text.dark" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" arrow>
              <IconButton onClick={handleOpenEdit}>
                <EditTwoToneIcon sx={{ color: "text.dark" }} />
              </IconButton>
            </Tooltip>
          </>
        }
        sx={{
          paddingY: 1,
          backgroundColor: "primary.main",
        }}
      />
      <CardContent>
        <Typography variant="body1">Phone: {props.job.phone}</Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ padding: "0" }}>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ width: "100%", borderRadius: 0 }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ textAlign: "right" }}>
              <TableRow>
                <TableCell component="th" scope="col" />
                <TableCell component="th" scope="col">
                  Watch
                </TableCell>
                <TableCell component="th" scope="col">
                  Cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {watches.map((watch) => (
                <Row key={watch.watchName} watch={watch} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>

      <Dialog
        open={deleteOpen}
        onClose={handleCloseDelete}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete Job: {props.job.number}?
        </DialogTitle>
        <DialogContent>
          <Typography>Job Number: {props.job.number}</Typography>
          <Typography>Name: {props.job.name}</Typography>
          <Typography>Email: {props.job.email}</Typography>
          <Typography>Phone: {props.job.phone}</Typography>
          <Typography>Estimate: {props.job.estimate}</Typography>
          <Typography>Comments: {props.job.comments}</Typography>
          <Typography>Status: {props.job.status}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleCloseEdit} maxWidth="xl">
        <JobForm
          job={props.job}
          editJob={props.editJob}
          updated={updated}
          handleCloseEdit={handleCloseEdit}
        />
      </Dialog>
    </Card>
  );
}
export default JobCard;
