import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const faqs = [
  {
    question: "How long will it take to repair my watch?",
    answer:
      "The time it takes to repair your watch can vary depending on the type of repair needed, availability of parts, and workload on the watchmaker. We try to provide our best estimate of completion time after inspecting the watch.",
  },
  {
    question: "How much will the repair cost?",
    answer:
      "The cost of a watch repair depends on the type of repair needed and the cost of any replacement parts. We provide a detailed estimate of the cost of the repair before beginning any work.",
  },
  {
    question: "What type of warranty do you offer on your repairs?",
    answer:
      "We offer a limited one year warranty on our repairs to the function of the watch (movement service, battery replacement, water repair). The warranty does not cover the customer's incidental damage to the watch.",
  },
  {
    question: "Do you fix smart watches?",
    answer: "No, we do not fix smart watches.",
  },
  {
    question: "Do you fix fake/replica watches?",
    answer:
      "No, we do not fix fake watches. We can perform authenticity checks.",
  },
];

function CustomAccordion(props) {
  const { faq } = props;
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      disableGutters
      square
      sx={{
        borderRadius: 0,
        borderBottom: props.borderBottom,
        borderColor: "#E1E1E1",
        paddingY: 2,
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === false ? (
            <AddIcon sx={{ marginRight: 1, paddingY: 2 }} />
          ) : (
            <RemoveIcon sx={{ marginLeft: 1, paddingY: 2 }} />
          )
        }
        sx={{
          flexDirection: "row-reverse",
          paddingX: 0,
          alignItems: "flex-start",
        }}
      >
        <Typography variant="body1" fontWeight={400}>
          {faq.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingX: 4 }}>
        <Typography variant="body2">{faq.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

const ContactPage = () => {
  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ paddingTop: { xs: 6, md: 10 }, paddingX: { xs: 3, md: 20 } }}
    >
      <Grid container>
        <Grid container item xs={12} marginBottom={5}>
          <Typography variant="title">Get in Contact</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          marginBottom={{ xs: 3 }}
          paddingRight={{ xs: 5, lg: 10 }}
          display="inline-flex"
          alignItems="flex-start"
        >
          <LocationOnOutlinedIcon
            sx={{
              color: "text.black",
              paddingRight: "10px",
              paddingTop: "3px",
            }}
          />
          <Typography
            component="a"
            href="http://maps.apple.com/?address=15800+Pines+Blvd+Suite+324,+Pembroke+Pines,+FL+33027&t=m&q=Swiss+Watch+Repair"
            target="_blank"
            variant="body2"
            color="text.black"
            sx={{ textDecorationLine: "none" }}
          >
            15800 Pines Blvd, Suite 324, Pembroke&nbsp;Pines, FL&nbsp;33027
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          marginBottom={{ xs: 3 }}
          display="inline-flex"
          alignItems="flex-start"
        >
          <LocalPhoneOutlinedIcon
            sx={{ color: "text.black", paddingRight: "10px" }}
          />
          <Typography
            component="a"
            href="tel:+19544386368"
            variant="body2"
            color="text.black"
            sx={{ textDecorationLine: "none" }}
          >
            (954) 438-6368
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row-reverse"
          marginBottom={{ xs: 5, md: 10 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            marginBottom={{ xs: 3, sm: 0 }}
            display="inline-flex"
            alignItems="center"
          >
            <EmailOutlinedIcon
              sx={{ color: "text.black", paddingRight: "10px" }}
            />
            <Typography
              component="a"
              href="mailto:dwwatchservice@gmail.com"
              variant="body2"
              color="text.black"
              sx={{ textDecorationLine: "none" }}
            >
              dwwatchservice@gmail.com
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            alignItems="flex-start"
            paddingRight={{ xs: 0, sm: 3 }}
          >
            <Typography variant="body2" fontWeight="600" display="inline">
              Business&nbsp;Hours:&nbsp;
              <Typography variant="body2" display="inline">
                M-F 9:30&nbsp;AM&nbsp;-&nbsp;5:00&nbsp;PM
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} marginBottom={{ xs: 7, md: 10 }}>
          <Box
            sx={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.8161703161354!2d-80.35867312302238!3d26.006838598429457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9a6f3b28e7e29%3A0x83f32a7ce69d8856!2sSwiss%20Watch%20Repair!5e0!3m2!1sen!2sus!4v1682467067327!5m2!1sen!2sus"
              title="Google map directions"
              allowFullScreen="false"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "0",
              }}
            ></iframe>
          </Box>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="h1">Frequently Asked Questions</Typography>
        </Grid>
        <Grid container item xs={12} marginY={3}>
          {faqs.map((faq, index, faqs) => {
            // no bottom border for last faq
            if (faqs.length - 1 === index) {
              return (
                <Grid item xs={12}>
                  <CustomAccordion
                    key={faq.service}
                    faq={faq}
                    borderBottom={0}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12}>
                  <CustomAccordion
                    key={faq.service}
                    faq={faq}
                    borderBottom={1}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;
