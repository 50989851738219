import React from "react";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Paper } from "@mui/material";

function createData(service, cost) {
  return { service, cost };
}

function clarifyDescription(desc) {
  return (
    desc.charAt(0).toUpperCase() +
    desc.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2")
  );
}

function WatchCard(props) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    console.log("Calculating rows in USEEFFECT");
    calculateRows();
  }, [props.watch]);

  const calculateRows = () => {
    let tempRows = [];
    for (var key in props.watch.description) {
      tempRows.push(
        createData(clarifyDescription(key), props.watch.description[key])
      );
    }
    setRows(tempRows);
  };

  return (
    <Paper
      sx={{
        borderRadius: 0,
        backgroundColor: "#F9FCFF",
        boxShadow: "3px 6px 13px 5px rgba(35, 43, 48, 0.12)",
        paddingX: { xs: 2, lg: 7 },
        paddingTop: 3,
        paddingBottom: { xs: 0, lg: 4 },
      }}
    >
      <Grid container paddingY={{ xs: 0, lg: 2 }}>
        <Grid container item xs={12}>
          <Typography variant="h2">{props.watch.name}</Typography>
        </Grid>
        <Grid container item xs={12} lg={6} marginTop={{ xs: 2, lg: 3 }}>
          <Typography variant="body2" fontWeight={600}>
            Estimate:&nbsp;
          </Typography>
          <Typography variant="body2">${props.watch.cost}</Typography>
        </Grid>

        {/* Table section */}
        <Grid container item xs={12} marginTop={{ xs: 1, lg: 5 }}>
          <TableContainer marginTop={5}>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingY: 2 }}>
                    <Typography variant="body1" fontWeight={600}>
                      Service
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ paddingY: 2 }}>
                    <Typography variant="body1" fontWeight={600}>
                      Cost
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.service}>
                    <TableCell component="th" sx={{ paddingY: 2 }}>
                      <Typography variant="body25">{row.service}</Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ paddingY: 2 }}>
                      <Typography variant="body25">{row.cost}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default WatchCard;
