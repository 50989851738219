import React from "react";
import CustomCarousel from "./customCarousel";
import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";

const HeroSection = () => {
  return (
    <Container
      position="relative"
      maxWidth
      disableGutters
      justifyContent="center"
      alignItems="center"
      paddingY={0}
    >
      <CustomCarousel />
      <Grid container item justifyContent="center" paddingX={2}>
        <Stack
          position={{ lg: "absolute" }}
          top={{ lg: "30%" }}
          left={{ lg: "7%" }}
          paddingTop="4rem"
          zIndex={1}
        >
          <Typography
            variant="title"
            color={{ xs: "text.black", lg: "text.light" }}
            marginBottom={{ xs: 1, lg: 2 }}
          >
            Repair with Care
          </Typography>
          <Typography
            variant="body1"
            color={{ xs: "text.black", lg: "text.light" }}
            fontWeight={300}
            marginBottom={{ xs: 3, lg: 5 }}
          >
            Restore your watch back to mint<br></br>condition with our trusted
            service.
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            href="/services"
            size="large"
            sx={{
              width: { xs: "100%", md: "100%", lg: "18rem" },
              ":hover": {
                bgcolor: "secondary.dark",
              },
            }}
          >
            View our services
          </Button>
          {/* <Button
            color="secondary"
            variant="contained"
            href="/trackJob"
            size="large"
            sx={{
              width: { xs: "100%", md: "100%", lg: "18rem" },
              ":hover": {
                bgcolor: "secondary.dark",
              },
            }}
          >
            Track Job
          </Button> */}
        </Stack>
      </Grid>
    </Container>
  );
};

export default HeroSection;
