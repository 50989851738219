import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import logo_white from "../images/logo_white.png";
import CloseIcon from "@mui/icons-material/Close";

const desktop_navigation = [
  { name: "About", href: "/about" },
  { name: "Services", href: "/services" },
  { name: "Contact", href: "/contact" },
];

const mobile_navigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Services", href: "/services" },
  { name: "Contact", href: "/contact" },
];

const NavBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List disablePadding>
        <ListItem
          key="logo"
          disablePadding
          sx={{
            justifyContent: "flex-end",
            color: "#FDFEFF",
            backgroundColor: "primary.main",
            paddingTop: "20px",
            paddingRight: "10px",
            paddingBottom: 2,
          }}
        >
          <CloseIcon fontSize="large" />
        </ListItem>
        <ListItem
          key="logo"
          disablePadding
          sx={{
            justifyContent: "center",
            backgroundColor: "primary.main",
            paddingBottom: 3,
          }}
        >
          <ListItemButton
            href="/"
            sx={{
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={logo_white}
              alt="logo"
              sx={{ width: "210px" }}
            />
          </ListItemButton>
        </ListItem>
        {mobile_navigation.map((item) => (
          <ListItem key={item.name} disablePadding divider={<Divider />}>
            <ListItemButton
              href={item.href}
              sx={{
                justifyContent: "center",
                padding: 2,
              }}
            >
              <NavLink
                key={item.name}
                to={item.href}
                style={({ isActive }) => {
                  return {
                    fontFamily: ["Source Sans Pro", "sans-serif"],
                    fontSize: "24px",
                    color: "#010716",
                    textDecoration: "none",
                    borderBottom: isActive ? "1px solid #010716" : "none",
                    fontWeight: isActive ? 600 : 400,
                  };
                }}
              >
                {item.name}
              </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem key={"Track Job"} disablePadding>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              mx: 1,
              my: 3,
              width: { xs: "100%", md: "100%", lg: "18rem" },
              ":hover": {
                bgcolor: "secondary.dark",
              },
            }}
          >
            <NavLink
              key="Track Job"
              to="/trackJob"
              style={({ isActive }) => {
                return {
                  fontFamily: ["Source Sans Pro", "sans-serif"],
                  fontSize: "24px",
                  color: "#FDFEFF",
                  fontWeight: isActive ? 600 : 400,
                  textDecoration: "none",
                };
              }}
            >
              Track Job
            </NavLink>
          </Button>
        </ListItem> */}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        position="sticky"
        elevation={0}
        sx={{ height: { xs: "70px", md: "100px" }, justifyContent: "center" }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <a href="/">
              <Box
                component="img"
                sx={{
                  maxWidth: { xs: 250, sm: 300 },
                  paddingY: 2,
                }}
                alt="logo"
                src={logo_white}
              />
            </a>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            {desktop_navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                style={({ isActive }) => {
                  return {
                    fontFamily: ["Source Sans Pro", "sans-serif"],
                    fontSize: "24px",
                    color: "#FDFEFF",
                    textDecoration: "none",
                    borderBottom: isActive ? "1px solid #FDFEFF" : "none",
                    fontWeight: isActive ? 600 : 400,
                    marginRight: 80,
                  };
                }}
              >
                {item.name}
              </NavLink>
            ))}
            {/* <Button
              color="secondary"
              variant="contained"
              sx={{
                marginRight: 1.5,
                paddingX: "35px",
                ":hover": {
                  bgcolor: "secondary.dark",
                },
              }}
            >
              <NavLink
                key="Track Job"
                to="/trackJob"
                style={({ isActive }) => {
                  return {
                    fontFamily: ["Source Sans Pro", "sans-serif"],
                    fontSize: "24px",
                    color: "#FDFEFF",
                    fontWeight: isActive ? 600 : 400,
                    textDecoration: "none",
                  };
                }}
              >
                Track Job
              </NavLink>
            </Button> */}
          </Box>
          <IconButton
            edge="start"
            aria-label="logo"
            onClick={handleDrawerToggle}
            sx={{
              display: { md: "none" },
            }}
          >
            <MenuIcon
              fontSize="large"
              sx={{
                color: "text.light",
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor={"right"}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "flex", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 260,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
export default NavBar;
