import React from "react";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import hero_rolex from "../../images/hero_rolex.png";
import hero_patek from "../../images/hero_patek.png";
import hero_iwc from "../../images/hero_iwc.png";

const images = [
  {
    src: hero_rolex,
    alt: "Rolex",
  },
  {
    src: hero_patek,
    alt: "Patek",
  },
  {
    src: hero_iwc,
    alt: "IWC",
  },
];

function Image(props) {
  return (
    <Box
      component="img"
      src={props.image.src}
      alt={props.image.alt}
      sx={{
        display: "flex",
        overflow: "hidden",
        width: "100vw",
        height: { xs: "80vh", lg: "90vh" },
        backgroundSize: "cover",
        objectFit: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
}

const CustomCarousel = () => {
  return (
    <Carousel stopAutoPlayOnHover={false} swipe={false}>
      {images.map((image, index) => (
        <Image key={index} image={image} />
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
