import React from "react";
import { useEffect, useState } from "react";
import { Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./fonts/style.css";

// components for our app
import HomePage from "./components/homePage";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import AboutPage from "./components/aboutPage";
import ProtectedRoute from "./components/protectedRoute";
import Login from "./components/login";
import JobList from "./components/jobList";
import TrackJob from "./components/trackJob";
import JobDetails from "./components/jobDetails";
import ServicePage from "./components/servicePage";
import ContactPage from "./components/contactPage";
import PrivacyPolicyPage from "./components/privacyPolicyPage";
import ErrorPage from "./components/errorPage";

let BaseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767, // phones
      md: 1200, // tablets
      lg: 1400, // laptops
      xl: 1920, // big monitors
    },
  },
});
BaseTheme = createTheme(BaseTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            textTransform: "none",
            fontWeight: 400,
            color: "text.light",
            lineHeight: 1.6,
            fontSize: "24px",
            fontFamily: "Source Sans Pro, sans-serif",
            [BaseTheme.breakpoints.down("lg")]: { fontSize: "22px" },
          },
        },
      ],
    },
  },

  palette: {
    primary: {
      main: "#172953", // navy blue
      light: "#455375",
      stepperBlue: "#062671",
      dark: "#101C3A",
    },
    secondary: {
      main: "#137DBB", // light blue
      light: "#4297C8",
      dark: "#0D5782",
    },
    info: {
      main: "#BFBFBF", // light gray
    },
    text: {
      light: "#FDFEFF", // white
      main: "#BFBFBF", // light gray
      dark: "#808080", // dark gray
      black: "#000000", // black
    },
    background: {
      white: "#FCFDFF", // slightly off-white
      blue: "#EAF6FF", // light blue-ish
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join("."),
    title: {
      fontFamily: "Domaine Display Narrow Regular",
      fontWeight: 600,
      letterSpacing: "0.06rem",
      fontSize: "56px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "40px" },
    },
    h1: {
      fontFamily: "Domaine Display Narrow Regular",
      fontWeight: 600,
      letterSpacing: "0.06rem",
      fontSize: "44px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "32px" },
    },
    h2: {
      fontFamily: "Domaine Display Narrow Regular",
      fontWeight: 600,
      letterSpacing: "0.06rem",
      fontSize: "40px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "30px" },
    },
    h3: {
      fontFamily: "Domaine Display Narrow Regular",
      fontWeight: 600,
      letterSpacing: "0.06rem",
      fontSize: "32px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "30px" },
    },
    body1: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: 300,
      fontSize: "24px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "22px" },
    },
    body2: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: 300,
      fontSize: "22px",
    },
    body25: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: 300,
      fontSize: "22px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "20px" },
    },
    body3: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: 300,
      fontSize: "20px",
    },
    body4: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: 300,
      fontSize: "18px",
    },
    footerText: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: 300,
      fontSize: "20px",
      [BaseTheme.breakpoints.down("lg")]: { fontSize: "16px" },
    },
  },
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <ThemeProvider theme={BaseTheme}>
      <div
        className="App"
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <title>Swiss Watch Repair</title>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicePage />} />
          {/* <Route path="/trackJob" element={<TrackJob />} /> */}
          {/* <Route path="/trackJob/:jobNumber" element={<JobDetails />} /> */}
          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
            <Route path="/jobs" element={<JobList />} />
          </Route>
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default App;
