import React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Container from "@mui/material/Container";
import omega_layout from "../images/omega_layout.jpg";

const services = [
  {
    service: "Complete Movement Service and Restoration (Overhaul)",
    description:
      "This is a comprehensive process of repairing and maintaining a mechanical watch movement to restore it to its original condition or better. The process involves disassembling, cleaning, repairing, and reassembling all the components of the movement. The movement is lubricated and tested to ensure its functionality and accuracy. All necessary adjustments are made to ensure it meets manufacturer specifications.",
  },
  {
    service: "Battery replacement",
    description:
      "We replace the battery in your watch to ensure that it keeps accurate time.",
  },
  {
    service: "Cleaning and polishing",
    description:
      "We clean and polish the watch's case, bracelet, and crystal to remove scratches, scuffs, and other blemishes from the watch's exterior surface to restore its original shine and finish.",
  },
  {
    service: "Watchband and strap replacement",
    description:
      "We replace the watch's band or strap with a new one that is the same or similar to the original. We can also size the strap according to the customer's specific needs.",
  },
  {
    service: "Crown and stem repair or replacement",
    description:
      "We repair or replace the watch's crown and stem, which are critical components of a watch that are used to set the time and date.",
  },
  {
    service: "Crystal replacement",
    description:
      "The crystal is the transparent cover that protects the watch face. We can replace the watch's crystal, making it look like new again.",
  },
  {
    service: "Water resistance testing and repair",
    description:
      "We test the watch's water resistance and fix any issues that are found. The process typically involves placing the watch in a specialized chamber that simulates various water pressures and depths to determine if it can withstand exposure to water. The watch will be restored to meet the manufacturer's specified water resistance rating.",
  },
  {
    service: "Authenticity check",
    description: "We inspect the watch and check its authenticity.",
  },
];

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const handleRowClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow onClick={handleRowClick} sx={{ cursor: "pointer" }}>
        <TableCell
          align="left"
          style={{
            borderBottom: "none",
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Box>
            <Typography variant="h3" display="inline">
              {row.service.slice(0, -1)}
            </Typography>
            <Box
              component="span"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                variant="h3"
                display="inline"
                marginRight={{ xs: 1, md: 2 }}
              >
                {row.service.slice(-1)}
              </Typography>
              <IconButton
                size="small"
                sx={{
                  padding: 0,
                  verticalAlign: "-20%",
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            paddingTop: 0,
            borderBottom: "none",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginBottom: { xs: 5, md: 10 } }}>
              <Typography variant="body2">{row.description}</Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ServicePage = () => {
  return (
    <>
      <Grid
        container
        xs={12}
        direction="row-reverse"
        marginBottom={{ xs: 0, md: 8, lg: 12 }}
      >
        <Grid container item xs={12} md={6}>
          <Box
            component="img"
            src={omega_layout}
            alt="omega_layout"
            width="100%"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          backgroundColor="background.blue"
          alignItems="flex-start"
          alignContent="center"
          paddingX={{ xs: "5%", md: "8%", xl: "10%" }}
          paddingY={{ xs: "10%", sm: "5%" }}
        >
          <Grid item container xs={12}>
            <Typography variant="title" marginBottom={3}>
              Available Services
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Typography variant="body1" lineHeight={1.5}>
              As a professional watch service company, we offer a range of watch
              repair and maintenance services to ensure that our customers'
              watches are running smoothly and looking their&nbsp;best.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Container maxWidth="xl" disableGutters>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          paddingTop={{ xs: 6, md: 0 }}
          paddingX={{ xs: 0, md: 20 }}
        >
          <Grid
            container
            item
            xs={12}
            marginTop={0}
            paddingX={{ xs: 5 }}
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="body1" maxWidth="sm">
              <Typography variant="body1">
                Don't see a service listed?{" "}
                <Typography
                  component="a"
                  href="tel:+19544386368"
                  variant="body1"
                  fontWeight="600"
                  color="black"
                  sx={{ textDecorationLine: "none" }}
                >
                  Call{" "}
                </Typography>
                or{" "}
                <Typography
                  component="a"
                  href="mailto:dwwatchservice@gmail.com"
                  variant="body1"
                  fontWeight="600"
                  color="black"
                  sx={{ textDecorationLine: "none" }}
                >
                  Email{" "}
                </Typography>
                to speak with us about any questions regarding watch services or
                to set up an&nbsp;appointment.
              </Typography>
            </Typography>
          </Grid>

          <Grid container item xs={12} marginY={{ xs: 6, md: 8 }}>
            <TableContainer
              marginY={5}
              component={Paper}
              sx={{
                borderRadius: 0,
                backgroundColor: "#F9FCFF",
                boxShadow: "3px 5px 12px 3px rgba(74, 82, 87, 0.1)",
                paddingX: { xs: 2, md: 5 },
                paddingTop: 5,
              }}
            >
              <Table
                sx={{ borderCollapse: "separate", borderSpacing: "0px 20px" }}
              >
                <TableBody>
                  {services.map((row) => (
                    <Row key={row.service} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ServicePage;
