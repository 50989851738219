import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import WatchCard from "./watchCard";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#137DBB",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#137DBB",
    },
  },
  [`&.${stepConnectorClasses.vertical}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#137DBB",
      height: 45,
      marginRight: 20,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#137DBB",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#0085D2",
    zIndex: 1,
    fontSize: 30,
  },
  "& .QontoStepIcon-circle": {
    width: 11,
    height: 11,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = [
  "Estimate completed",
  "Waiting for approval",
  "Waiting for parts",
  "Job started",
  "Quality check",
  "Completed: Ready for pickup",
  "Delivered: Picked up",
];

const JobDetails = () => {
  const [job, setJob] = useState("");
  const [firstName, setFirstName] = useState("");
  const [validJob, setValidJob] = useState(true);
  const [watches, setWatches] = useState([]);
  const [status, setStatus] = useState(0);
  const [stepperOrientation, setStepperOrientation] = useState("horizontal");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /**
   * Sets the progress orientation based on window width.
   * Only triggered when windowWidth changes.
   */
  useEffect(() => {
    if (windowWidth < 1200) {
      setStepperOrientation("vertical");
    } else {
      setStepperOrientation("horizontal");
    }
  }, [windowWidth]);

  /**
   * Sets the window width whenever it is resized
   */
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  let { jobNumber } = useParams();

  /**
   * Gets the job details the first time the page is loaded
   */
  useEffect(() => {
    getJob();
  }, []);

  /**
   * Get the first name of the job
   * @param  {[string]} name Full name of the job
   * @return {[string]}      First name of the full name
   */
  const getFirstName = (name) => {
    return name.split(" ")[0];
  };

  /**
   * API call to database to get a specific job number and set the proper values
   */
  const getJob = async () => {
    try {
      const response = await axios.get(`${API}/trackJob/${jobNumber}`, {
        params: { number: jobNumber },
      });
      if (response.data.length === 0) {
        alert('Error: Job Number "' + jobNumber + '" could not be found');
        setValidJob(false);
      }
      setJob(response.data[0]);
      setWatches(response.data[0].watches);
      mapStatus(response.data[0].status);
      setFirstName(getFirstName(response.data[0].name));
    } catch (err) {
      alert("Error retrieving job number " + jobNumber);
    }
  };

  /**
   * Maps the status of the job from string to a number for the stepper
   * @param  {[string]} status status of the job
   */
  const mapStatus = (status) => {
    status = status.toLowerCase();
    if (status === "estimate") {
      setStatus(0);
    } else if (status === "approval") {
      setStatus(1);
    } else if (status === "parts") {
      setStatus(2);
    } else if (status === "started") {
      setStatus(3);
    } else if (status === "qualitycheck") {
      setStatus(4);
    } else if (status === "completed") {
      setStatus(5);
    } else if (status === "delivered") {
      setStatus(7);
    }
  };

  /**
   * Checks if the job is valid. If not, redirects to Track Job page
   */
  if (!validJob) {
    return <Navigate to={"/trackJob"} />;
  }

  return (
    <Container maxWidth="lg" sx={{ paddingX: 0, marginTop: { xs: 7, lg: 10 } }}>
      {/* Job Detail info section */}
      <Grid
        container
        xs={12}
        rowSpacing={2}
        paddingX={{ xs: 2, md: 15 }}
        marginBottom={{ xs: 9, md: 12 }}
      >
        <Grid container item xs={12}>
          <Typography variant="title">Welcome, {firstName}</Typography>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Typography variant="body2" fontWeight={600}>
            Job Number:&nbsp;
          </Typography>
          <Typography variant="body2">{job.number}</Typography>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Typography variant="body2" fontWeight={600}>
            Email:&nbsp;
          </Typography>
          <Typography variant="body2">{job.email}</Typography>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Typography variant="body2" fontWeight={600}>
            Total Cost:&nbsp;
          </Typography>
          <Typography variant="body2">${job.estimate}</Typography>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Typography variant="body2" fontWeight={600}>
            Phone:&nbsp;
          </Typography>
          <Typography variant="body2">{job.phone}</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="body2" fontWeight={600}>
            Comments:&nbsp;
          </Typography>
          <Typography variant="body2">{job.comments}</Typography>
        </Grid>
      </Grid>

      {/* Job Order Status Section */}
      <Grid container paddingX={0} marginBottom={{ xs: 9, md: 12 }}>
        <Grid
          container
          item
          xs={12}
          marginBottom={{ xs: 3, md: 5 }}
          justifyContent={{ xs: "center", md: "flex-start" }}
          paddingX={{ xs: 2, md: 15 }}
        >
          <Typography variant="h1" fontWeight={600}>
            Order Status
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          paddingX={{ md: 5 }}
        >
          <Stepper
            alternativeLabel={stepperOrientation === "horizontal"}
            activeStep={status}
            connector={<QontoConnector />}
            orientation={stepperOrientation}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            {steps.map((label) => (
              <Step
                key={label}
                sx={{
                  "& .Mui-disabled .MuiStepIcon-root": {
                    color: "primary.stepperBlue",
                    fontSize: { md: "1.8rem" },
                  },
                  "& .Mui-disabled .MuiStepLabel-label": {
                    color: "text.black",
                  },
                  "& .Mui-completed .MuiStepConnector-root": {
                    marginRight: "10px",
                  },
                  "& .Mui-disabled .MuiStepConnector-alternativeLabel": {
                    marginRight: 10,
                  },
                  "& .MuiStepLabel-root": {
                    display: { xs: "block", md: "flex" },
                  },
                  "& .MuiStepLabel-iconContainer": {
                    justifyContent: "center",
                  },
                }}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>

      {/* Watches section */}
      <Grid
        container
        marginTop={{ xs: 0, md: 10 }}
        marginBottom={{ xs: 1, lg: 3 }}
        paddingX={0}
      >
        <Grid
          container
          item
          xs={12}
          paddingX={{ xs: 2, md: 15 }}
          marginBottom={{ xs: 2, md: 4 }}
        >
          <Typography variant="h1" fontWeight={600}>
            Watches ({watches.length})
          </Typography>
        </Grid>
        {React.Children.toArray(
          watches.map((watch) => (
            <Grid
              item
              xs={12}
              paddingX={{ xs: 0, md: 15 }}
              marginBottom={{ xs: 5, md: 7 }}
            >
              <WatchCard watch={watch} editable={false} />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};
export default JobDetails;
