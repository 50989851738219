import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import services_patek from "../../images/services_patek.png";

const ServiceSection = () => {
  return (
    <>
      <Grid item container xs={12} lg={6}>
        <Box
          component="img"
          src={services_patek}
          alt="Patek Phillipe"
          width="100%"
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        lg={6}
        backgroundColor="background.blue"
        alignItems="flex-start"
        alignContent="center"
        padding={{ xs: 5, lg: 15, xl: 20 }}
      >
        <Grid item container xs={12}>
          <Typography variant="h1" marginBottom={3}>
            We Take Pride in&nbsp;Our Range&nbsp;of&nbsp;Services
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography variant="body2" marginBottom={5}>
            We offer many different services to fit your&nbsp;needs&nbsp;best.
          </Typography>
        </Grid>
        <Grid item container xs={12} lg={8} xl={5}>
          <Button
            color="secondary"
            variant="contained"
            href="/services"
            size="large"
            fullWidth
          >
            View Our Services
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceSection;
