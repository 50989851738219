import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Divider from "@mui/material/Divider";
import logo_white from "../images/logo_white.png";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

const column1_elements = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Services", href: "/services" },
  // { name: "Track\u00A0Job", href: "/trackJob" },
];

const column2_elements = [
  { name: "Contact", href: "/contact" },
  { name: "FAQ", href: "/contact" },
  { name: "Privacy\u00A0Policy", href: "/privacy" },
];

const column3_elements = [
  {
    name: "(954)-438-6368",
    href: "tel:+19544386368",
    icon: LocalPhoneOutlinedIcon,
  },
  {
    name: "dwwatchservice@gmail.com",
    href: "mailto:dwwatchservice@gmail.com",
    icon: EmailOutlinedIcon,
  },
  {
    name: "15800 Pines Blvd, Suite 324, Pembroke Pines, FL\u00A033027",
    href:
      "http://maps.apple.com/?address=15800+Pines+Blvd+Suite+324,+Pembroke+Pines,+FL+33027&t=m&q=Swiss+Watch+Repair",
    icon: LocationOnOutlinedIcon,
  },
];

function Copyright() {
  return (
    <Stack
      alignItems={{ xs: "flex-start", lg: "center" }}
      justifyContent="center"
    >
      <Box
        component="img"
        src={logo_white}
        alt="logo"
        sx={{ width: { xs: "250px", lg: "300px" } }}
      ></Box>
      <Typography variant="subtitle1" color="text.dark" paddingBottom={2}>
        © Copyright {new Date().getFullYear()}
      </Typography>
    </Stack>
  );
}

const Footer = () => {
  return (
    <Grid
      container
      maxWidth
      justifyContent="center"
      paddingTop={{ xs: 5, lg: 10 }}
      sx={{
        backgroundColor: "primary.main",
        marginTop: "auto",
      }}
    >
      <Grid
        container
        item
        maxWidth={{ sm: "55%", lg: "60%" }}
        direction="row-reverse"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid
          container
          item
          xs={12}
          lg={4}
          justifyContent="center"
          paddingTop={{ xs: 5, lg: 0 }}
          paddingX={{ xs: 5, md: 0 }}
          marginBottom={{ xs: 3 }}
        >
          <Stack spacing={2}>
            {column3_elements.map((item) => (
              <Grid key={item.name} item>
                <Link
                  href={item.href}
                  variant="footerText"
                  target="_blank"
                  sx={{ color: "#FDFEFF", textDecoration: "none" }}
                  display="inline-flex"
                >
                  <item.icon sx={{ paddingRight: "6px" }} />
                  {item.name}
                </Link>
              </Grid>
            ))}
          </Stack>
        </Grid>
        <Grid container item xs={12} lg={3}></Grid>
        <Grid
          container
          item
          xs={6}
          lg={2}
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <Stack spacing={2}>
            {column2_elements.map((item) => (
              <Typography
                component="a"
                href={item.href}
                variant="footerText"
                color="#FDFEFF"
                sx={{ textDecorationLine: "none" }}
              >
                {item.name}
              </Typography>
            ))}
          </Stack>
        </Grid>
        <Grid
          container
          item
          xs={6}
          lg={3}
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <Stack spacing={2}>
            {column1_elements.map((item) => (
              <Typography
                component="a"
                href={item.href}
                variant="footerText"
                color="#FDFEFF"
                sx={{ textDecorationLine: "none" }}
              >
                {item.name}
              </Typography>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid item maxWidth xs={12}>
        <Divider color="#BFBFBF" sx={{ marginX: "4%", marginY: 9 }} />
      </Grid>
      <Grid container item xs={12} justifyContent="center" marginBottom={5}>
        <Link href="/">
          <Copyright />
        </Link>
      </Grid>
    </Grid>
  );
};
export default Footer;
