import React, { useEffect, useState } from "react";
import JobCard from "./jobCard";
import JobForm from "./jobForm";
import Login from "./login";
import { API } from "../config";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [jobFilter, setJobFilter] = useState("");
  const [edited, setEdited] = useState(false);
  const [created, setCreated] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);

  const handleOpenCreate = () => {
    setCreateOpen(true);
  };

  const handleCloseCreate = () => {
    setCreateOpen(false);
  };

  const createJob = () => {
    setCreated((created) => !created);
    handleCloseCreate();
  };

  const deleteJob = (number) => {
    const newJobs = jobs.filter((job) => job.number !== number);
    setJobs(newJobs);
  };

  const editJob = () => {
    console.log("editJob of jobList.js");
    setEdited((edited) => !edited);
  };

  const getJobs = () => {
    axios.get(`${API}/jobs/`).then((response) => setJobs(response.data));
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    getJobs();
  }, [jobs.length, edited, created]);

  return (
    <Container maxWidth="lg" sx={{ padding: 5, margin: "auto" }}>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Job List
      </Typography>
      <Container
        maxWidth="md"
        sx={{ padding: 2, margin: "auto", textAlign: "center" }}
      >
        <TextField
          variant="outlined"
          placeholder="Search Job Number..."
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Job Status</InputLabel>
          <Select
            value={jobFilter}
            label="Job Status"
            onChange={(e) => setJobFilter(e.target.value)}
          >
            <MenuItem value={""}>All</MenuItem>
            <MenuItem value={"Open"}>Open</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
            <MenuItem value={"Closed"}>Closed</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="success"
          fullWidth
          startIcon={<AddIcon />}
          onClick={handleOpenCreate}
        >
          Add New Job
        </Button>
      </Container>

      <Grid container spacing={4} sx={{ marginY: 0 }}>
        {React.Children.toArray(
          jobs
            .filter((job) => {
              if (jobFilter === "" && searchTerm === "") {
                return jobs;
              } else if (
                jobFilter === "" &&
                job.number.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return job;
              } else if (
                jobFilter === "Closed" &&
                job.status === "Delivered" &&
                job.number.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return job;
              } else if (
                jobFilter === "Completed" &&
                job.status === "Completed" &&
                job.number.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return job;
              } else if (
                jobFilter === "Open" &&
                job.status !== "Delivered" &&
                job.status !== "Completed" &&
                job.number.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return job;
              }
            })
            .map((job) => (
              <Grid item xs={4}>
                <JobCard job={job} deleteJob={deleteJob} editJob={editJob} />
              </Grid>
            ))
        )}
      </Grid>

      <Dialog open={createOpen} onClose={handleCloseCreate} maxWidth="xl">
        <JobForm
          createJob={createJob}
          editJob={editJob}
          handleCloseCreate={handleCloseCreate}
        />
      </Dialog>
    </Container>
  );
};

export default JobList;
