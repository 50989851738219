import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import hublot from "../images/about_hublot.jpg";
import dennis from "../images/dennis.jpg";
import watch1_before from "../images/watch1_before.jpg";
import watch1_after from "../images/watch1_after.jpg";
import longines_automatic from "../images/longines_automatic.jpg";
import omega_deville from "../images/omega_deville.jpg";
import iwc_chronograph from "../images/iwc_chronograph.jpg";
import patek_geneve from "../images/patek_geneve.jpg";
import rolex_oyster_perpetual from "../images/rolex_oyster_perpetual.jpg";
import audemars_piguet from "../images/audemars_piguet.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const images = [
  {
    src: omega_deville,
    title: "Omega De Ville",
  },
  {
    src: patek_geneve,
    title: "Patek Philippe Geneve",
  },
  {
    src: iwc_chronograph,
    title: "IWC Chronograph",
  },
  {
    src: rolex_oyster_perpetual,
    title: "Rolex Oyster Perpetual Datejust",
  },
  {
    src: audemars_piguet,
    title: "Audemars Piguet",
  },
  {
    src: longines_automatic,
    title: "Longines Automatic",
  },
];

function AboutPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      <Grid
        container
        xs={12}
        direction="row-reverse"
        marginBottom={{ xs: 0, md: 15, lg: 20 }}
      >
        <Grid container item xs={12} md={6}>
          <Box component="img" src={hublot} alt="Hublot" width="100%" />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          backgroundColor="background.blue"
          alignItems="flex-start"
          alignContent="center"
          paddingX={{ xs: "5%", md: "8%", xl: "10%" }}
          paddingY={{ xs: "10%", sm: "5%" }}
        >
          <Grid item container xs={12}>
            <Typography variant="title" marginBottom={3}>
              Providing High Quality Watch&nbsp;Care
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Typography variant="body1" lineHeight={1.5}>
              At Swiss Watch Repair, we treat each watch as though it were our
              own with the utmost dedication, care, and
              attention&nbsp;to&nbsp;detail.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        direction="row"
        marginBottom={{ xs: 5, md: 10, lg: 15 }}
      >
        <Grid container item xs={12} md={6} justifyContent="center">
          <Box
            component="img"
            src={dennis}
            alt="Dennis"
            width={{ xs: "100%", lg: "95%", xl: "80%" }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          alignItems="flex-start"
          alignContent="center"
          paddingX={{ xs: "5%", md: "8%", xl: "10%" }}
          paddingY={{ xs: "5%" }}
        >
          <Grid item container xs={12}>
            <Typography variant="h1" marginBottom={3}>
              Our Story
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Typography variant="body2" lineHeight={1.5}>
              Dennis is a professional Swiss factory trained watchmaker
              (Breitling, Cartier, and Rolex), and has over 20 years of
              experience in the fine art of watch repair and restoration. His
              expertise allows him to reliably provide first-class service to
              customers.
              <br></br>
              <br></br>
              Dennis worked at Mayors Jewelers and Breitling Service Center as a
              Watchmaker for many years, perfecting the art of restoring
              timepieces. In 2011, Dennis was inspired to open his own repair
              shop to offer outstanding quality and exceptionally beautiful work
              directly to you.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        <Typography variant="h1" marginBottom={3}>
          Our Work
        </Typography>
      </Grid>
      <Grid
        container
        marginBottom={{ xs: 2, md: 0 }}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        width="100%"
      >
        <Grid
          container
          item
          justifyContent="center"
          width={{ xs: "100%", md: "70%" }}
          direction={{ xs: "column", md: "row" }}
        >
          <Grid
            container
            item
            xs={5}
            justifyContent="center"
            marginBottom={{ xs: 10, md: 15 }}
          >
            <Grid container item justifyContent="center">
              <Typography variant="body1" marginBottom={3}>
                Before
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Box
                component="img"
                src={watch1_before}
                alt="Before"
                width={{ xs: "80%", md: "100%" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={2}
            justifyContent="center"
            alignItems="center"
            marginBottom={10}
          >
            {windowWidth >= 1200 && <ArrowForwardIcon fontSize="large" />}
            {windowWidth < 1200 && <ArrowDownwardIcon fontSize="large" />}
          </Grid>
          <Grid
            container
            item
            xs={5}
            justifyContent="center"
            marginBottom={{ xs: 10, sm: 20, md: 15 }}
          >
            <Grid container item justifyContent="center">
              <Typography variant="body1" marginBottom={3}>
                After
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Box
                component="img"
                src={watch1_after}
                alt="After"
                width={{ xs: "80%", md: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Image collage */}
      <Grid container marginBottom={{ xs: 5, md: 10 }} justifyContent="center">
        {windowWidth >= 1200 && (
          <Box sx={{ width: "70%" }}>
            <ImageList variant="masonry" cols={3} gap={15}>
              {images.map((item) => (
                <ImageListItem key={item.img}>
                  <img src={item.src} alt={item.title} />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        )}
        {windowWidth < 1200 && (
          <Grid container xs={12} marginBottom={3}>
            {images.map((item) => (
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                marginBottom={3}
              >
                <Box
                  component="img"
                  src={item.src}
                  alt={item.title}
                  width="80%"
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default AboutPage;
