import React, { useEffect, useState } from "react";
import HeroSection from "./homepageSection/heroSection";
import HowItWorksSection from "./homepageSection/howItWorksSection";
import OurStorySection from "./homepageSection/ourStorySection";
import ReviewSection from "./homepageSection/reviewSection";
import ServiceSection from "./homepageSection/serviceSection";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

const HomePage = (props) => {
  const [stepperOrientation, setStepperOrientation] = useState("horizontal");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (windowWidth < 1200) {
      setStepperOrientation("vertical");
    } else {
      setStepperOrientation("horizontal");
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Container disableGutters maxWidth="100%">
      <Grid
        container
        justifyContent="center"
        backgroundColor="background.white"
      >
        {/* Hero section */}
        <Grid item xs={12} marginBottom={{ xs: 12, lg: 15 }}>
          <HeroSection />
        </Grid>

        {/* How it Works section*/}
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          paddingX={{ xs: 0, lg: 10 }}
          marginBottom={{ xs: 3, md: 7, lg: 13 }}
        >
          <HowItWorksSection stepperOrientation={stepperOrientation} />
        </Grid>

        {/* Our Story section */}
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          marginBottom={{ xs: 8, md: 10, lg: 15 }}
          direction="row-reverse"
        >
          <OurStorySection />
        </Grid>

        {/* Reviews section */}
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          marginBottom={{ xs: 15, lg: 25 }}
          paddingX={{ xs: 5, lg: 15, xl: 20 }}
        >
          <ReviewSection windowWidth={windowWidth} />
        </Grid>

        {/* Services section */}
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          marginY={0}
          direction="row"
        >
          <ServiceSection />
        </Grid>
      </Grid>
    </Container>
  );
};
export default HomePage;
