import React from "react";
import { useState } from "react";
import axios from "axios";
import { API } from "../config";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

function Login({ setIsLoggedIn }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API}/users/${username}/${password}`, {
        params: {
          username: username,
          password: password,
        },
      });
      if (response.status === 200) {
        alert("Success! Logging you in...");
        setIsLoggedIn(true);
        navigate("/jobs");
      } else {
        alert("ERROR: Incorrect username and password");
      }
    } catch (err) {
      alert("ERROR: Incorrect username and password");
    }
  };

  return (
    <Container sx={{ padding: 5, margin: "auto" }}>
      <div align="center">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Log in</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Username"
                type="text"
                autoComplete="off"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                disabled={!username || !password}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default Login;
